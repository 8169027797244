"use client";
import React from "react";
import Bugsnag from "@bugsnag/js";
import Intercom from "@intercom/messenger-js-sdk";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import mixpanel from "mixpanel-browser";

import { cn } from "@/lib/utils";
import { fontSans } from "@/lib/fonts";

import { Toaster } from "@/components/ui/sonner";
import { ThemeProvider } from "@/components/providers";
import { Authenticated } from "@/components/authentication";

import "./globals.css";

if (process.env.NODE_ENV !== "development") {
  Bugsnag.start({
    apiKey: process.env.NEXT_PUBLIC_BUGSNAG_API_KEY,
    plugins: [new BugsnagPluginReact()],
  });
}

if (process.env.NODE_ENV === "production") {
  mixpanel.init(process.env.NEXT_PUBLIC_MIX_PANEL_PROJECT_TOKEN, {
    debug: true,
    track_pageview: true,
    persistence: "localStorage",
  });
}
const ErrorBoundary =
  process.env.NODE_ENV !== "development"
    ? Bugsnag.getPlugin("react").createErrorBoundary(React)
    : ({ children }) => <>{children}</>;

Intercom({
  app_id: "bsy8itni",
});

export default function RootLayout({ children }) {
  return (
    <html className="h-full" lang="en" suppressHydrationWarning>
      <body
        className={cn(
          "min-h-screen font-sans antialiased h-full",
          fontSans.className
        )}
      >
        <ErrorBoundary>
          <ThemeProvider
            defaultTheme="system"
            enableSystem
            disableTransitionOnChange
          >
            <div className="h-full" vaul-drawer-wrapper="">
              <div className="relative flex h-full flex-col bg-[#1e262b]">
                <Authenticated>{children}</Authenticated>
              </div>
            </div>
          </ThemeProvider>
          <Toaster />
        </ErrorBoundary>
      </body>
    </html>
  );
}
